<!-- =========================================================================================
    File Name: Register.vue
    Description: Register Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <vx-card>
      <div slot="no-body" class="full-page-bg-color">
        <iframe
          width="100%"
          allow="camera *;microphone *"
          height="600"
          :src="meeting_link"
          v-if="meeting_link"
          allowfullscreen
        ></iframe>
        <div class="p-10" v-else>
          <img
            src="logo.png"
            :alt="`${$t('basic.Logo')}`"
            class="block m-auto mb-5"
          />
          <h3 class="text-center text-color-base">{{ siteTitle }}</h3>
        </div>
      </div>
    </vx-card>
  </div>
</template>
<script>

// Validator
import axios from 'axios'

export default {


  data () {
    return {
      meeting_link: ''
    }
  },
  created () {
    this.createMeeting()
  },
  methods: {
    createMeeting () {
      axios.get(`/bbb-integration/start-quick-meeting/${this.$route.params.meeting_id}`).then(res => {
        this.meeting_link = res
      })

    }
  },
  computed:{

  }
}
</script>
